.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App {
  background-color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  font-weight: 300;
}

.App-top {
  height: 60vh;
}

.App-mid {
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 30vh;
}

.App-bottom {
  height: 10vh;
  font-weight: 300;
}

.App-page {
  height: 100vh;
  font-size: .75em;
}

.App-mid-left {
  width: 50vw;
  text-align: right;
}

.left-divider {
  border-right: 1px solid #fff;
  padding-right: 2vw;
}

.App-mid-right {
  width: 48vw;
  margin-left: 2vw;
  font-weight: 100;
  text-align: left;
}

.App-content {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.App canvas {
  height: 100vh;
  width: 100vw;
}

.App-link {
  color: #61dafb;
}

h1 {
  font-weight: 300;
}

a {
  color: #f6c9a0;
  text-decoration: none;
}